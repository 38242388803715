<template>
  <div
    ref="$el"
    class="marquee"
    :class="{'no-pause-on-hover': noPauseOnHover}"
  >
    <ul
      class="marquee__content"
      :class="{'marquee__content--reverse': isReverse}"
    >
      <li
        v-for="(element, i) in elements"
        :key="i"
      >
        <slot :element="element" />
      </li>
    </ul>
    <ul
      class="marquee__content"
      :class="{'marquee__content--reverse': isReverse}"
      aria-hidden="true"
    >
      <li
        v-for="(element, i) in elements"
        :key="i"
      >
        <slot :element="element" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
interface Props {
  direction?: 'ltr' | 'rtl',
  time?: string | null,
  elements: Array<any>
  noPauseOnHover?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  direction: 'rtl',
  time: null,
  elements: () => [],
  noPauseOnHover: false
})

const $el = ref<HTMLDivElement>()

onMounted(() => {
  if (props.time) {
    setTime(props.time)
  }
})

const isReverse = computed(() => {
  return props.direction === 'ltr'
})

function setTime (time: string) {
  const els = $el.value?.querySelectorAll('.marquee__content') as NodeListOf<HTMLElement> | undefined
  els?.forEach(el => el.style.setProperty('--time', time))
}
</script>

<style lang="scss" scoped>
.press-articles {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 15px 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);

  img {
    height: 70px;
  }
}

.marquee {
  --gap: 1rem;

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__content {
    --time: 30s;

    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
    animation: scroll var(--time) linear infinite;

    &--reverse {
      animation-direction: reverse;
    }
  }

  &:hover .marquee__content {
    animation-play-state: paused;
  }

  &.no-pause-on-hover {
    &:hover .marquee__content {
      animation-play-state: unset;
    }
  }
}

@keyframes scroll {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(calc(-100% - var(--gap)), 0, 0);
  }
}
</style>
